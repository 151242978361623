import CheckedIcon from 'app/assets/svgs/Checked-2-icon.svg';
import { ReactComponent as BackIcon } from 'app/assets/svgs/back-icon.svg';
import { JoinZoom } from 'app/components/Buttons/JoinZoom';
import DateText from 'app/components/DateText/DateText';
import { Timezone } from 'app/components/DateText/Timezone';
import StatusComponent from 'app/components/StatusTitle/StatusComponent';
import Dash from 'app/components/Typography/Dash';
import Dot from 'app/components/Typography/Dot';
import Typography from 'app/components/Typography/Typography';
import SubjectTabNavItem from 'app/features/subjects/components/SubjectTabNavItem';
import { utcToLocalDateTime } from 'app/utils/utils';
import _ from 'lodash';
import styled from 'styled-components';
import { CheckinLogCell } from '../../CheckinLogCell';

const ClassCheckersHeaderStyled = styled.div`
  background-color: #f7f9fc;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
`;
const BackButton = styled(BackIcon)`
  margin: 20px 20px 0 0;
  padding: 0;
  cursor: pointer;
`;

interface ClassCheckersHeaderProps {
  lessonData: any;
}

export const ClassCheckersHeader = ({ lessonData }: ClassCheckersHeaderProps) => {
  return (
    <ClassCheckersHeaderStyled>
      <div
        className="card position-relative border-0"
        style={{ borderRadius: '10px', paddingTop: '19px', paddingLeft: '15px', paddingBottom: '19px' }}
      >
        <div className="d-flex flex-col gap-2 w-100">
          <BackButton
            className="col-auto"
            onClick={() => {
              window.history.back();
            }}
          />
          <div className="row justify-content-between">
            <div className="d-flex flex-row col">
              <div>
                <h5 className="mb-0 fw-bolder fs-3 d-flex align-items-center gap-2">
                  {lessonData?.data?.attributes?.class?.data?.attributes?.year?.data?.attributes?.shortName}{' '}
                  {lessonData?.data?.attributes?.class?.data?.attributes?.subject?.data?.attributes?.name} <Dot />
                  <DateText
                    date={utcToLocalDateTime(
                      lessonData?.data.attributes.classDate!,
                      lessonData?.data.attributes.startTime
                    ).toISOString()}
                    format={'DD_MMM_YYYY'}
                  />
                  <Dot />
                  {_.capitalize(lessonData?.data.attributes.class?.data?.attributes?.dayOfWeek)}
                </h5>
                <div className="mb-0 d-flex align-items-center gap-2">
                  {lessonData?.data.attributes.class?.data?.attributes?.classId} <Dash />
                  <span className="text-muted d-flex align-items-center">
                    <DateText
                      date={utcToLocalDateTime(
                        lessonData?.data.attributes.classDate!,
                        lessonData?.data.attributes.startTime
                      ).toISOString()}
                      format={'time'}
                    />
                    <Dash />
                    <DateText
                      date={utcToLocalDateTime(
                        lessonData?.data.attributes.classDate!,
                        lessonData?.data.attributes.endTime
                      ).toISOString()}
                      format={'time'}
                    />
                    (<Timezone />) <Dot />
                  </span>
                  <Typography type="text" width="200px">
                    <span className="text-primary fw-bolder">
                      {lessonData?.data.attributes?.teacher?.data?.attributes?.name}{' '}
                      {lessonData?.data.attributes?.teacher?.data?.attributes?.lastName}
                    </span>
                    <Dot />
                    <span className="text-primary fw-bolder">
                      Zoom Account:
                      {lessonData?.data.attributes.class?.data?.attributes?.zoomAccount}
                    </span>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <StatusComponent
            className={`my-auto py-1 ${
              lessonData?.data.attributes?.meetingStatus == 'upcoming'
                ? 'cancelled'
                : lessonData?.data.attributes?.meetingStatus
            }`}
            status={lessonData?.data.attributes?.meetingStatus!}
            alt={lessonData?.data.attributes?.meetingStatus == 'ended' ? 'checked-icon' : undefined}
            icon={lessonData?.data.attributes?.meetingStatus == 'ended' ? CheckedIcon : undefined}
          />
          <JoinZoom
            link={lessonData?.data.attributes.class?.data?.attributes?.zoomLink}
            status={lessonData?.data.attributes?.meetingStatus}
            id={lessonData?.data?.id}
            logs={lessonData?.data?.attributes?.checkinLogs}
            zoomId={lessonData?.data?.attributes.class?.data?.attributes?.zoomMeetingId}
            passcode={lessonData?.data?.attributes.class?.data?.attributes?.zoomMeetingPasscode}
          />
          <CheckinLogCell logs={lessonData?.data?.attributes?.checkinLogs} />
        </div>
      </div>
      <ul className="nav bg-transparent nav-overflow nav-tabs mt-2 ">
        <SubjectTabNavItem link={'register'} title="Register" wrapperClasses="ps-2 ps-sm-4" />
      </ul>
    </ClassCheckersHeaderStyled>
  );
};
