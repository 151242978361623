import { File } from 'app/components/v1/FileUploader';
import { ApiResponseData, Country, UploadedFile, UserType } from 'app/services/types';
import { EventPayload } from 'app/services/web-socket/ws-socket.service';
import JSZip from 'jszip';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { getCountries } from './get-countries';

export const EMPTY_DATA_MARK = '-';

export function getUserTypeOfUrl(url: string): string | undefined {
  const userTypes = Object.keys(UserType).map((userType: string) => userType.toLowerCase());
  const urlParts = url?.split('/')?.filter((part: string) => part !== '');
  const userType = userTypes.find((userType) => urlParts?.at(0)?.toLowerCase() === userType);
  return userType ? userType : undefined;
}
export function download(url: string, filename: string) {
  const linkElement = document.createElement('a');
  linkElement.setAttribute('href', url);
  linkElement.setAttribute('download', filename);
  linkElement.setAttribute('target', '_blank');
  document.body.appendChild(linkElement);
  linkElement.click();
  linkElement.remove();
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function zipFiles(zip: JSZip, folders: { name: string; urls: string[] }[]): Promise<Blob> {
  for (const folder of folders) {
    const filesFolder = zip.folder(folder.name);
    for (const url of folder.urls) {
      const response = await fetch(url);
      const data = await response.blob();
      filesFolder?.file(`${Math.floor(Math.random() * 100000)}-${url.split('/').pop()}`, data, {
        base64: true
      });
    }
  }

  const content = await zip.generateAsync({ type: 'blob' });
  return content;
}

export function getTimeIntervals(fromMinute: number, toMinute: number, timeInterval?: number): TimeInterval[] {
  const intervals: TimeInterval[] = [];
  for (let i = fromMinute; i < toMinute; i += timeInterval || 15) {
    const timeInterval = moment(i * 60000).format('HH:mm');
    intervals.push({
      milliseconds: i * 60000,
      time: timeInterval,
      value: utcToLocalDateTime(moment().format('YYYY-MM-DD'), timeInterval).format('HH:mm')
    });
  }
  return intervals;
}

export function mapToFile(uploadedFile: ApiResponseData<UploadedFile>) {
  return {
    key: uploadedFile.id?.toString()!,
    id: uploadedFile.id?.toString(),
    url: uploadedFile.attributes.url,
    name: uploadedFile.attributes.name,
    type: uploadedFile.attributes?.mime
  } as File;
}

export function dateFormat() {
  return 'YYYY-MM-DD';
}
export function timeFormat() {
  return 'hh:mm a';
}
export function dateTimeFormat() {
  return 'YYYY-MM-DDTHH:mm';
}

export type TimeInterval = {
  milliseconds: number;
  time: string;
  value: string;
};

export function fullDateFormat() {
  return `${dateFormat()} ${timeFormat()}`;
}

export async function convertInputFileListToFile(fileList: FileList): Promise<File[]> {
  const fileConvertingPromises: Promise<File>[] = [];

  for (let fI = 0; fI < fileList.length; fI++) {
    fileConvertingPromises.push(
      new Promise((resolve, error) => {
        const file = fileList.item(fI);
        const fileReader = new FileReader();
        fileReader.onload = function (e) {
          const key = _.uniqueId();
          resolve({
            key,
            id: key,
            name: file?.name,
            url: window.URL.createObjectURL(file!),
            type: file?.type,
            file
          } as File);
        };
        fileReader.readAsText(file!);
      })
    );
  }
  const files: File[] = [];
  for (const fileConvertingPromise of fileConvertingPromises) {
    files.push(await fileConvertingPromise);
  }
  return files;
}

export function getCurrency(): string {
  return '£';
}

export function toPriceFormat(value: number) {
  return `${getCurrency()} ${parseFloat((value || 0).toString()).toFixed(2)}`;
}

export function getEntityLink(userType: string, eventPayload: EventPayload): string | undefined {
  if (!eventPayload.entityId) return;
  switch (eventPayload.entity) {
    case 'teacher':
      return `${userType?.toLowerCase()}/teachers/${eventPayload?.entityId}`;
    case 'student':
      return `${userType?.toLowerCase()}/students/${eventPayload?.entityId}`;
    case 'class':
      return `${userType?.toLowerCase()}/classes/${eventPayload?.entityId}`;
    case 'registerStudent':
      if (eventPayload.eventCode == '1009') {
        return `${userType?.toLowerCase()}/teacher-feedback/${eventPayload?.entityId}`;
      }
  }
}

export function getDefaultCountry(): Country {
  return getCountries()[0];
}

export function splitTextBy(text: string, delimiter: string) {
  return text
    ?.trim()
    .split(delimiter)
    ?.filter((e) => e !== ' ')
    .map((e) => e.trim());
}

export function isNullOrUndefined(value: any): boolean {
  return _.isNull(value) || _.isUndefined(value) || value === '';
}

export function utcToLocalDateTime(
  date: string,
  time?: string,
  options?: { useCurrentTimezoneOffset?: boolean }
): Moment {
  if (options?.useCurrentTimezoneOffset) {
    return moment(date).startOf('day').add(moment.duration(time)).add(moment().utcOffset(), 'minute');
  }
  const dateTime = moment.utc(date);
  if (time) {
    dateTime.add(moment.duration(time));
  }
  return dateTime.local();
}

export function localDateTimeToUtc(
  date: string,
  time?: string,
  options?: { useCurrentTimezoneOffset?: boolean }
): Moment {
  if (options?.useCurrentTimezoneOffset) {
    return moment(date).startOf('day').add(moment.duration(time)).subtract(moment().utcOffset(), 'minute');
  }
  const dateTime = moment(date);
  if (time) {
    dateTime.add(moment.duration(time));
  }
  return moment.utc(dateTime);
}

export function utcTimeToLocalTime(time: string) {
  return utcToLocalDateTime(moment().utc(false).startOf('year').startOf('day').format(), time, {
    useCurrentTimezoneOffset: true
  });
}

/**
 * if the file url is for s3 then return it as is if it doesn't then return the local url.
 */
export function getFileUrl(image: any) {
  let data: UploadedFile = {} as UploadedFile;
  if (image?.provider) data = image;
  else if (image?.data?.provider) data = image?.data;
  else if ((_.first(image?.data) as ApiResponseData<UploadedFile>)?.attributes?.provider)
    data = (_.first(image?.data) as ApiResponseData<UploadedFile>)?.attributes;
  else return _.includes(image?.url, 'http') ? image?.url : process.env.REACT_APP_API_URL + image?.url;
  const { provider, url } = data;
  if (provider == 'aws-s3') return url;
  else return process.env.REACT_APP_API_URL + url;
}

export function getDaysFromDate(dateString: string): number {
  const millisecondsPerDay: number = 1000 * 60 * 60 * 24;
  const givenDate: Date = new Date(dateString);
  const currentDate: Date = new Date();
  const difference: number = currentDate.getTime() - givenDate.getTime();
  return Math.floor(difference / millisecondsPerDay);
}

export function getTimeDiffrence(date: string) {
  let totalDiff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 1000;

  const days = Math.floor(totalDiff / 86400);
  totalDiff -= days * 86400;

  const hours = Math.floor(totalDiff / 3600) % 24;
  totalDiff -= hours * 3600;

  const minutes = Math.floor(totalDiff / 60) % 60;
  totalDiff -= minutes * 60;

  return { days, hours, minutes, seconds: Math.round(totalDiff) };
}

export const displayTimeDiffrence = (timestamp: string) => {
  const diff = getTimeDiffrence(timestamp);
  if (diff.days != 0) {
    if (diff.days <= 6) {
      return `${diff.days} Days ago`;
    } else {
      return new Date(timestamp).toLocaleString();
    }
  } else if (diff.hours != 0) {
    return `${diff.hours} ${diff.hours == 1 ? 'Hour' : 'Hours'} ago`;
  } else if (diff.minutes != 0) {
    return `${diff.minutes} Minutes ago`;
  } else return `${diff.seconds} Seconds ago`;
};

export function timeDifference(startTime: string, endTime: string): string {
  // Parse the time strings into Date objects
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  const startDate = new Date(0, 0, 0, startHour, startMinute, 0);
  const endDate = new Date(0, 0, 0, endHour, endMinute, 0);

  // Calculate the difference in milliseconds
  const diffInMs = endDate.getTime() - startDate.getTime();

  // Convert the difference from milliseconds to hours
  const diffInHours = diffInMs / (1000 * 60 * 60);

  return diffInHours.toFixed(2);
}

export function sortLessons(lessons: any, key: string) {
  return lessons.sort((a: any, b: any) => {
    return new Date(a[key]).getTime() - new Date(b[key]).getTime();
  });
}

export function subtractMinutes(time: string, minutes: number) {
  const [hours, minutesPart, seconds] = time.split(':');
  const date = new Date();

  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutesPart));
  date.setSeconds(parseInt(seconds));

  date.setMinutes(date.getMinutes() - minutes);

  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
    date.getSeconds()
  ).padStart(2, '0')}`;
}
