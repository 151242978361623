import moment from 'moment';
import * as yup from 'yup';
import { dateFormat } from '../utils';

interface IConfig {}

interface IName extends IConfig {
  msg: string;
}

interface IDateOfBirth extends IConfig {
  msg: string;
}
interface IPhoneNumberConfig extends IConfig {
  patternMsg?: string;
  maxMsg?: string;
}

interface IDateTimeConfig extends IConfig {
  name: string;
}

interface IUrlConfig extends IConfig {
  message?: string;
}

export const url = (config?: IUrlConfig) => {
  const urlPattern =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/gm;
  return yup
    .string()
    .label('URL')
    .nullable()
    .optional()
    .matches(urlPattern, config?.message ?? 'URL is not valid');
};

export const dateTime = (config?: IDateTimeConfig) => {
  return yup
    .string()
    .label('date')
    .nullable()
    .test({
      name: config?.name,
      message: 'Invalid ${label} format',
      test: (value) => moment(value).isValid()
    });
};

export const phoneNumber = (config?: IPhoneNumberConfig) =>
  yup
    .string()
    .label('phone')
    .trim()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      config?.patternMsg || '${label} is not valid'
    )
    .max(18, config?.maxMsg || '${label} can be only ${max} digits long')
    .required('Please enter ${label}');

export const dateOfBirth = (config?: IDateOfBirth) =>
  yup
    .date()
    .label('date of birth')
    .nullable()
    .max(
      moment().subtract(1, 'year').format(dateFormat()),
      config?.msg || 'The minimum age for the person can be 1 year old'
    )
    .notRequired();

export const name = (config?: IName) =>
  yup
    .string()
    .label('name')
    .nullable()
    .transform((curr: string) => (curr ? curr.replace(/\s+$/, '') : curr))
    .required(config?.msg || 'Please enter ${label}')
    .max(100, config?.msg || 'Maximum allowed chars is ${max}');

export const password = (config?: IName) =>
  yup
    .string()
    .label('Password')
    .transform((curr: string) => (curr ? curr.replace(/\s+$/, '') : curr))
    .min(8, '${label} must be at least ${min} characters')
    .required('${label} is required');

export const passwordConfirmation = (config?: IName) =>
  yup
    .string()
    .label('Password confirmation')
    .transform((curr: string) => (curr ? curr.replace(/\s+$/, '') : curr))
    .required()
    .oneOf([yup.ref('password'), null as any], 'Passwords must match');

export const classId = (config?: IConfig) =>
  yup.string().label('class id').nullable().required('Please enter ${label}');

export const subject = (config?: IConfig) =>
  yup.string().label('subject').nullable().required('Please select ${label}');

export const year = (config?: IConfig) => yup.mixed().label('year').nullable().required('Please select ${label}');

export const startDate = (config?: IConfig) =>
  yup.string().label('start date').nullable().required('Please enter ${label}');

export const endDate = (config?: IConfig) =>
  yup.string().label('end date').nullable().required('Please enter ${label}');

export const startTime = (config?: IConfig) =>
  yup
    .string()
    .label('start time')
    .nullable()
    .test('classroomInfo', 'Please enter ${label}', (value, context) => {
      if (
        (!context.parent.isManual || context.parent.isManual === false) &&
        (value === undefined || value === null || value === '')
      ) {
        return false;
      }
      return true;
    });

export const endTime = (config?: IConfig) =>
  yup.string().label('tnd time').nullable().required('Please enter ${label}');

export const classType = (config?: IConfig) =>
  yup.string().label('class type').nullable().required('Please select ${label}');

export const teacher = (config?: IConfig) =>
  yup.string().label('teacher').nullable().required('Please select a ${label}');

export const status = (config?: IConfig) => yup.string().label('status').nullable().required('Please select ${label}');

export const recordingLink = (config?: IConfig) =>
  url().when('noRecording', {
    is: false,
    then: (schema) => yup.string().label('recording link').nullable().required('Please enter a valid ${label}')
  });

export const meetingType = (config?: IConfig) =>
  yup.string().label('meeting type').nullable().required('Please select ${label}');

export const lessonFiles = (config?: IConfig) =>
  yup
    .array()
    .label('resources')
    .required('One or more ${label} are required')
    .min(1, '${label} field must have at least ${min} item');

export const questions = (config?: IConfig) =>
  yup
    .array()
    .label('questions')
    .required('${label} is required')
    .min(1, '${label} field must have at least ${min} item');

export const solutions = (config?: IConfig) =>
  yup
    .array()
    .label('solutions')
    .required('${label} is required')
    .min(1, '${label} field must have at least ${min} item');

export const totalMarks = (config?: IConfig) =>
  yup
    .number()
    .label('total marks')
    .required('${label} are required')
    .nullable()
    .min(1, '${label} field must be greater than 0')
    .max(100);

export const deadline = (config?: IConfig) =>
  yup.string().label('deadline').nullable().required('${label} date is required');

export const linkedIn = (config?: IConfig) =>
  yup
    .string()
    .label('linkedIn')
    .transform((curr: string) => curr?.replace(/\s+$/, ''))
    .nullable()
    .url()
    .test(
        'teacherProfileRequired',
        'Please upload a resume or add your LinkedIn profile',
        (options, context) => {
          return context.parent.cv || context.parent.linkedInProfile;
        }
      );

export const shortBio = (config?: IConfig) =>
  yup
    .string()
    .label('short bio')
    .transform((curr: string) => (curr ? curr.replace(/\s+$/, '') : curr))
    .nullable()
    .min(10, '${label} must be at least ${min} characters long')
    .max(250, '${label} must be at most ${max} characters long')
    .optional();

export const longBio = (config?: IConfig) =>
  yup
    .string()
    .label('long bio')
    .transform((curr: string) => (curr ? curr.replace(/\s+$/, '') : curr))
    .nullable()
    .min(100, '${label} must be at least ${min} characters long')
    .max(5000, '${label} must be at most ${max} characters long')
    .optional();

export const phoneCountryCode = (config?: IConfig) => yup.string().label('Phone code').nullable().required();

export const email = (config?: IConfig) =>
  yup
    .string()
    .nullable()
    .label('email')
    .transform((curr: string) => (curr ? curr.replace(/\s+$/, '') : curr))
    .required('Please enter ${label}')
    .email();

export const country = (config?: IConfig) => yup.mixed().label('country').required('Please select your ${label}');

export const schoolName = (config?: IConfig) =>
  yup
    .string()
    .label('school name')
    .transform((curr: string) => (curr ? curr.replace(/\s+$/, '') : curr))
    .max(100, '${label} must be at most ${max} characters long')
    .nullable()
    .trim()
    .optional();

export const SEN = (config?: IConfig) => yup.string().label('SEN').nullable().trim().optional();

export const dayOfWeek = (config?: IConfig) =>
  yup
    .string()
    .label('week of day')
    .nullable()
    .test('classroomInfo', 'Please select the ${label} for class', (value, context) => {
      if (
        (!context.parent.isManual || context.parent.isManual === false) &&
        (value === undefined || value === null || value === '')
      ) {
        return false;
      }
      return true;
    });

export const rate = (config?: IConfig) =>
  yup.number().nullable().label('rate').moreThan(-1, '${label} should not less than zero');

export const postcode = (config?: IConfig) => yup.string().label('post code').nullable().optional();

export const description = (config?: IConfig) =>
  yup.string().label('description').nullable().trim().required('Please enter ${label}');

export const color = (config?: IConfig) => yup.string().label('color').nullable().required('Please choose ${label}');

export const date = (config?: IConfig) => yup.date().label('date').required('Please enter ${label}');

export const paymentDay = (config?: IConfig) =>
  yup
    .number()
    .min(1, 'Payment day should be between 1 and 31')
    .max(31, 'Payment day should be between 1 and 31')
    .nullable()
    .required('Payment day is required');

export * as yup from 'yup';
