import { ReactComponent as PasswordIcon } from 'app/assets/svgs/ic_view Fill 2.svg';
import _ from 'lodash';
import React, { CSSProperties, FocusEventHandler, useCallback, useEffect, useId, useMemo, useState } from 'react';
import styled from 'styled-components';

// --------------------------------------------------------------------------------------------------------
export function FormControl(
  props: React.PropsWithChildren<{
    className?: string;
    style?: any;
    floatingLabel?: boolean;
    status?: 'error';
  }>
) {
  const formControlErrors = useMemo(() => {
    return (
      _.toArray(props.children as React.ReactNode).find((child: any) => child?.type?.name === 'Errors') ||
      props.status === 'error'
    );
  }, [props.children]);
  return (
    <div
      className={`st-v1-form-control form-floating ${formControlErrors ? 'has-errors' : ''} ${props.className || ''}`}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
// --------------------------------------------------------------------------------------------------------
const InputContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    color: #44444f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    &.with-suffix {
      padding-left: 20px;
    }
  }
`;
const InputSuffixStyled = styled.span`
  position: absolute;
  margin-left: 10px;
`;
export function Input(props: React.PropsWithChildren<InputProps>) {
  const inputId = useId();
  const [value, setValue] = useState<string>('');
  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);
  const debounceOnChange = useMemo(() => {
    return _.debounce((value) => {
      props.onChangeDebouncing && props.onChangeDebouncing(value);
    }, props.debouncing || 300);
  }, [props.onChangeDebouncing]);

  const handleOnChange = useCallback(
    (e: any) => {
      setValue(e?.target?.value!);
      props.onChange && props.onChange(e?.target?.value!);
      props.onChangeDebouncing && debounceOnChange.cancel();
      props.onChangeDebouncing && debounceOnChange(e?.target?.value!);
    },
    [debounceOnChange, props.value, value, props.onChange]
  );
  return props.suffix ? (
    <InputContainerStyled>
      <InputSuffixStyled>{props.suffix}</InputSuffixStyled>
      <input
        id={inputId}
        type={props.type || 'text'}
        className={`form-control  ${props.className || ''} ${props.suffix && 'with-suffix'}`}
        placeholder={' '}
        value={value}
        disabled={props.disabled}
        onKeyUp={props.onKeyUp && props.onKeyUp}
        onChange={handleOnChange}
      />
      <label htmlFor={inputId}>{props.name}</label>
    </InputContainerStyled>
  ) : (
    <>
      <input
        id={inputId}
        type={props.type || 'text'}
        className={`form-control  ${props.className || ''}`}
        placeholder={' '}
        value={value}
        disabled={props.disabled}
        onKeyUp={props.onKeyUp && props.onKeyUp}
        onChange={handleOnChange}
        onBlur={props.onBlur}
        inputMode={props.inputMode}
      />
      <label htmlFor={inputId}>{props.name}</label>
    </>
  );
}

export type InputProps = {
  name: string;
  type?: 'text' | 'number' | 'password' | 'color' | 'tel';
  suffix?: string | JSX.Element;
  value?: any;
  style?: React.CSSProperties;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChangeDebouncing?: (value: string) => void;
  debouncing?: number;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  inputMode?: 'search' | 'text' | 'tel' | 'email' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;
};

// --------------------------------------------------------------------------------------------------------

export function InputPassword(props: React.PropsWithChildren<InputPasswordProps>) {
  const [isShown, setIsShown] = useState(false);
  return (
    <>
      <Input {...props} type={isShown ? 'text' : 'password'} />
      <PasswordIcon
        onClick={() => setIsShown((state) => !state)}
        style={{
          position: 'absolute',
          right: '18px',
          top: '25%',
          width: '1rem'
        }}
      />
    </>
  );
}
export type InputPasswordProps = InputProps & {
  name: string;
};

// --------------------------------------------------------------------------------------------------------
export function TextArea(props: React.PropsWithChildren<TextAreaProps>) {
  const inputId = useId();
  const handleOnChanged = useCallback((e: any) => props.onChange && props.onChange(e.target.value), [props.onChange]);
  return (
    <>
      <textarea
        id={inputId}
        className={`form-control ${props.className || ''}`}
        placeholder={props.name}
        onChange={handleOnChanged}
        value={props.value}
        rows={props.rows}
        cols={props.cols}
        style={props.style}
        disabled={props.disabled}
      />
      {props.showLabel != undefined && !props.showLabel ? <></> : <label htmlFor={inputId}>{props.name}</label>}
    </>
  );
}

export type TextAreaProps = {
  name?: string;
  type?: 'text' | 'number';
  value?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  errors?: { [key: string]: string[] };
  rows?: number;
  cols?: number;
  style?: CSSProperties;
  onChange?: (value: string) => void;
  showLabel?: boolean;
};
// --------------------------------------------------------------------------------------------------------
const ErrorsStyled = styled.ul`
  list-style: none;
  margin: 4px 0 0 0;
  padding: 0px;
  font-size: 12px;
  max-width: 80%;
  color: #fc5a5a;

  li::first-letter {
    text-transform: capitalize;
  }
`;

const inputErrorPrefix = 'input-error';
export function Errors(props: { errors: FormControlError; className?: string; style?: CSSProperties }) {
  return (
    <ErrorsStyled className={`${inputErrorPrefix} ${props.className || ''}`} style={props.style}>
      {Object.keys(props.errors).map((error, i) => {
        return (
          <div key={i}>
            {Array.isArray(props.errors[error])
              ? props.errors[error].map((error, idx) => <li key={idx}>{error}</li>)
              : props.errors[error]}
          </div>
        );
      })}
    </ErrorsStyled>
  );
}

/**
 * scroll to the first error or based on the error index
 * by getting all the errors and call 'scrollTo' with the error Index.
 */
export function useScrollToError() {
  function scrollTo(errorIndex?: number) {
    const errorElement: HTMLElement = document
      .querySelectorAll(`.${inputErrorPrefix}`)
      .item(errorIndex || 0) as HTMLElement;
    if (!errorElement) return;
    errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  function getErrors() {
    const errorElements: string[] = [];
    for (const error in document.querySelectorAll(`.${inputErrorPrefix}`).entries()) {
      errorElements.push(error);
    }
    return errorElements;
  }
  return { getErrors, scrollTo };
}

export type FormControlError = Record<string, Array<string | React.ReactNode | JSX.Element>>;
