import * as Sentry from '@sentry/react';
import { AppContext } from 'AppContext';
import { message } from 'antd';
import familyLinkApi from 'app/services/family-link';
import { AdditionalPermissions, UserType } from 'app/services/types';
import { userApi } from 'app/services/user';
import { classesSlice, lessonSlice, notificationsSlice, userStore } from 'app/store';
import storage, { STORAGE_KEYS } from 'app/utils/storage';
import { getIp, getUserTypeOfUrl } from 'app/utils/utils';
import { useContext, useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Portal } from '../../services/web-socket/ws-socket.service';

export function useAuth() {
  const appContext = useContext(AppContext);
  const userId = useRef<string>(storage.getItem(STORAGE_KEYS.USER_ID)!);
  const location = useLocation();
  const [getUser, { isLoading: isUserDataLoading }] = userApi.useLazyGetUserQuery();
  const [findByEntityId] = familyLinkApi.useLazyFindByEntityIdQuery();
  const [authStatus, setAuthStatus] = useState<
    'authenticated' | 'loggedOut' | 'unauthenticated' | 'loading' | undefined
  >('loading');
  const [profile, setProfile] = useState<any>();
  const [userType, setUserType] = useState<string>();
  const [userData, setUserData] = useState<any>();
  const [familyLinks, setFamilyLinks] = useState<any>();
  const store = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (!userId.current) {
        storage.clear();
        setAuthStatus('loggedOut');
        if (
          !location.pathname.match(
            /(signup|login|forgot-password|reset-password|verify-email|create-password|account-confirmation)/g
          )
        ) {
          navigate('/login', { replace: true });
        }
        return;
      }

      initialize(userId.current).then(async ({ user, profileData }: any) => {
        const userTypes = user?.user_types?.map((e: any) => e?.name?.toLowerCase());
        const currentUserType =
          userTypes?.includes(UserType.Admin?.toLowerCase()) &&
          user?.additionalPermission === AdditionalPermissions.MEETING_RECORDING
            ? 'admin-meeting-recording'
            : userTypes?.at(0)!;
        appContext.connect({
          entityId: profileData?.id,
          userId: user?.id?.toString()!,
          userType: currentUserType as UserType,
          portal: currentUserType as Portal
        });

        let id = profileData?.id;
        const email = profileData?.email;
        if (userTypes?.at(0) === UserType.Teacher?.toLocaleLowerCase()) {
          id = profileData?.teacherId;
        } else if (userTypes?.at(0) === UserType.Student?.toLocaleLowerCase()) {
          id = profileData?.studentId;
        } else if (userTypes?.at(0) === UserType.Parent?.toLocaleLowerCase()) {
          id = profileData?.parentId;
        }

        Sentry.setUser({
          id,
          email,
          username: user?.username,
          ip_address: await getIp()!
        });
      });
    } catch (error) {
      setAuthStatus('unauthenticated');
      message.error("Couldn't load your data");
    }
  }, [userId.current]);

  const login = async (userId: string, token: string, refreshToken: string) => {
    storage.setItem(STORAGE_KEYS.USER_ID, userId);
    storage.setItem(STORAGE_KEYS.TOKEN, token);
    storage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
    const { profileData, user } = await initialize(userId);
    storeUser(user);
    navigateToDashboard(user?.user_types?.at(0)?.name?.toLocaleLowerCase() || '');
    const userTypes = user?.user_types?.map((e: any) => e?.name?.toLowerCase());
    const currentUserType =
      userTypes?.includes(UserType.Admin?.toLowerCase()) &&
      user?.additionalPermission === AdditionalPermissions.MEETING_RECORDING
        ? 'admin-meeting-recording'
        : userTypes?.at(0)!;

    appContext.connect({
      entityId: profileData?.id,
      userId: user?.id?.toString()!,
      userType: currentUserType as UserType,
      portal: currentUserType as Portal
    });
  };

  const storeUser = (user: any) => {
    setAuthStatus('authenticated');
    window.localStorage.setItem('user', JSON.stringify(user));
    setProfile(user?.teacher || user?.parent || user?.student);
    setUserData(user);
    setUserType(user.user_types?.at(0)?.name?.toLocaleLowerCase());
  };

  const navigateToDashboard = (userType: string) => {
    if (userType === 'admin') {
      navigate('/admin', { replace: true });
    } else if (userType === 'teacher') {
      navigate('/teacher', { replace: true });
    } else if (userType === 'parent') {
      navigate('/parent', { replace: true });
    } else if (userType === 'student') {
      navigate('/student', { replace: true });
    }
  };

  const logout = () => {
    appContext.disconnect();
    setAuthStatus('loggedOut');
    setUserData(undefined);
    setProfile(undefined);
    setUserType(undefined);
    store.dispatch(notificationsSlice.actions.reset());
    store.dispatch(userStore.actions.reset());
    store.dispatch(classesSlice.actions.reset());
    store.dispatch(lessonSlice.actions.reset());
    storage.clear();
    window.location.replace('/login');
  };

  const initialize = async (userId: string) => {
    try {
      const user = await getUser(userId).unwrap();
      const profileData = user?.teacher || user?.parent || user?.student || user?.admin;
      if (!profileData) return {};
      setProfile(profileData);
      setUserType(user?.user_types?.at(0)?.name?.toLowerCase());
      setUserData(user);
      const userType = getUserTypeOfUrl(location.pathname);
      if (userType === user?.user_types?.at(0)?.name?.toLowerCase()) {
        setAuthStatus('authenticated');
      } else {
        setAuthStatus('unauthenticated');
      }

      const familyLinkKeyInfo = await findByEntityId({
        type: userType!,
        entityId: profileData?.id
      }).unwrap();

      const familyLinks = familyLinkKeyInfo
        ? {
            ids: [familyLinkKeyInfo.data.familyLinkId],
            students: familyLinkKeyInfo.data.studentIds,
            parents: familyLinkKeyInfo.data.parentIds
          }
        : null;

      const userActionPayload = {
        ...user,
        familyLinks,
        entity: profileData,
        userType
      };

      store.dispatch(userStore.actions.userLoaded(userActionPayload));
      setFamilyLinks(familyLinks);
      return {
        user,
        profileData
      };
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  return {
    userType,
    authStatus,
    profile,
    userData,
    familyLinks,
    loading: isUserDataLoading || authStatus === 'loading' || !authStatus,
    setAuthStatus,
    login,
    logout,
    navigateToDashboard
  };
}
