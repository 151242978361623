import { AppContext } from 'AppContext';
import { Tooltip } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ReactComponent as StarIcon } from 'app/assets/Star 2.svg';
import { ReactComponent as StartClickedIcon } from 'app/assets/Star.svg';
import { ReactComponent as UnPaidIcon } from 'app/assets/ic_Spam.svg';
import { ReactComponent as PaidIcon } from 'app/assets/paidIcon.svg';
import { ReactComponent as DownArrow } from 'app/assets/svgs/ic_Chevron Down.svg';
import { ReactComponent as UpArrow } from 'app/assets/svgs/ic_Chevron Up.svg';
import { ReactComponent as NoteIcon } from 'app/assets/svgs/ic_Notes.svg';
import { IconComponents } from 'app/components/Icons';
import Avatar from 'app/components/Icons/Avatar';
import { HomeworkV2 } from 'app/components/Marker/HomeworkV2';
import StatusComponent from 'app/components/StatusTitle/StatusComponent';
import { Button } from 'app/components/v1/Button';
import { FormControl } from 'app/components/v1/Input';
import { Select } from 'app/components/v1/Select';
import StudentHomework from 'app/features/markers/student-homework/StudentHomework';
import HomeworkButton from 'app/features/subjects/show-subject/homeworks/components/HomeworkButton';
import { useUpdateRegisterStudentMutation } from 'app/services/register-student';
import {
  ApiResponseData,
  EntityId,
  HomeworkTypes,
  LessonMaterial,
  Register,
  RegisterStatues,
  StudentClass
} from 'app/services/types';
import { EventPayload } from 'app/services/web-socket/ws-socket.service';
import { AppStore, MAIN_SELECTORS, notificationsSlice } from 'app/store';
import { utcToLocalDateTime } from 'app/utils/utils';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SubmittingStateContext } from '..';
import { StudentRegisterNotes } from '../components/StudentRegisterNotes';
import StudentTeacherNotes from '../components/StudentTeacherNotes';

const LessonRegisterStyled = styled.div`
  background: #ffffff;
  padding: 40px;
  .student-avatar {
    background: #e7e7e7;
    border-radius: 14px;
  }

  .student-name {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #44444f;
  }

  .student-id {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #92929d;
  }

  .table-header {
    background: #f1f1f5;
    border-radius: 10px;
    padding: 15px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #92929d;
  }

  .table-row {
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 10px;
    padding: 15px;
  }
  .select {
    width: 100%;
  }
`;

const HomeworkMarkingBtnStyled = styled(Button)`
  cursor: pointer;
`;

const options = {
  attendance: [
    { label: <span style={{ color: '#3DD598' }}>Attended</span>, value: 'attended' },
    { label: <span style={{ color: '#FFC542' }}>Late</span>, value: 'late' },
    { label: <span style={{ color: '#FFC542' }}>Left early</span>, value: 'leftEarly' },
    {
      label: <span style={{ color: '#FFC542' }}>Late & left early</span>,
      value: 'leftEarly&Late'
    },
    { label: <span style={{ color: '#FC5A5A' }}>Absent</span>, value: 'absent' }
  ],
  engagement: [
    { label: <span style={{ color: '#3DD598' }}>Proactive</span>, value: 'proactive' },
    { label: <span style={{ color: '#FFC542' }}>Attentive</span>, value: 'attentive' },
    { label: <span style={{ color: '#FC5A5A' }}>Distracted</span>, value: 'distracted' },
    { label: <span style={{ color: '#FC5A5A' }}>Unknown</span>, value: 'unknown' },
    { label: <span style={{ color: '#FC5A5A' }}>Absent</span>, value: 'absent', disabled: true }
  ],
  understanding: [
    { label: <span style={{ color: '#3DD598' }}>Mastered</span>, value: 'mastered' },
    { label: <span style={{ color: '#FFC542' }}>Understood</span>, value: 'understood' },
    { label: <span style={{ color: '#FC5A5A' }}>Struggled</span>, value: 'struggled' },
    { label: <span style={{ color: '#FC5A5A' }}>Unknown</span>, value: 'unknown' },
    { label: <span style={{ color: '#FC5A5A' }}>Absent</span>, value: 'absent', disabled: true }
  ]
};

export default function LessonRegister(props: LessonRegisterProps) {
  const { values, setValues, disabled, lessonData, refetch } = useContext(SubmittingStateContext);
  const [updateRegisterStudent, { isLoading: loadingUpdate }] = useUpdateRegisterStudentMutation();
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [studentId, setStudentId] = useState('');

  const user = useSelector(MAIN_SELECTORS.user);
  const notificationSelector = useSelector((state: AppStore) => state[notificationsSlice.name]);
  const [isHomeworkDisplay, setIsHomeworkDisplay] = useState<string | null | undefined>();
  const [selectedRegister, setSelectedRegister] = useState<string | null | undefined>();

  useEffect(() => {
    const notification: EventPayload = notificationSelector?.notification;
    if (notification && notification.background && values?.registeredStudents) {
      const { registerStudentId, attendance, engagement, understanding } = notification.messageValues || {};
      const attendanceValue = !_.isEmpty(attendance) && getAttendanceValue(attendance)?.value;
      const registerStudent = values?.registeredStudents?.find(
        (registerStudent: any) => registerStudent.id.toString() === registerStudentId
      );
      if (registerStudent && Object.keys(attendance).length != 0) {
        if (attendanceValue) registerStudent.attendance = attendanceValue;
        if (attendanceValue == undefined) {
          registerStudent.attendance = null;
          registerStudent.engagement = null;
          registerStudent.understanding = null;
        }
        if (engagement) registerStudent.engagement = engagement;
        if (engagement == 'empty') registerStudent.engagement = null;
        if (understanding) registerStudent.understanding = understanding;
        if (understanding == 'empty') registerStudent.understanding = null;

        if (attendanceValue == 'absent') {
          registerStudent.engagement = 'absent';
          registerStudent.understanding = 'absent';
          registerStudent.starStudent = false;
          registerStudent.issueDescription = null;
          registerStudent.issueReasons = null;
          registerStudent.issueSendToParent = null;
        } else {
          registerStudent.engagement = registerStudent?.engagement === 'absent' ? null! : registerStudent?.engagement;
          registerStudent.understanding =
            registerStudent?.understanding === 'absent' ? null! : registerStudent?.understanding;
        }
      }

      setValues({ ...values });
    }
  }, [notificationSelector?.notification, values?.registeredStudents]);

  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.joinRoom({
      userId: user?.id?.toString()!,
      entityId: lessonData?.data?.id,
      userType: user.userType,
      portal: user.userType,
      entity: 'register'
    });
    return () => {
      appContext.leaveRoom({
        userId: user?.id?.toString()!,
        entityId: lessonData?.data?.id,
        userType: user.userType,
        portal: user.userType,
        entity: 'register'
      });
    };
  }, [lessonData]);

  const lessonStatus = lessonData?.data?.attributes?.status;
  const handleOnChange = (type: handleOnChangeTypes, registerStudentId: string, value: any) => {
    const registerStudent = values?.registeredStudents.find(
      (registerStudent: any) => registerStudent.id.toString() === registerStudentId
    );
    if (!registerStudent) return;
    switch (type) {
      case handleOnChangeTypes.ATTENDANCE:
        registerStudent.attendance = value;
        if (value === 'absent') {
          registerStudent.engagement = 'absent';
          registerStudent.understanding = 'absent';
          registerStudent.starStudent = false;
          registerStudent.issueDescription = null;
          registerStudent.issueReasons = null;
          registerStudent.issueSendToParent = null;
          registerStudent.attendance = value;
        } else if (!value) {
          registerStudent.attendance = null;
          registerStudent.engagement = null;
          registerStudent.understanding = null;
        } else {
          registerStudent.engagement = registerStudent?.engagement === 'absent' ? null! : registerStudent?.engagement;
          registerStudent.understanding =
            registerStudent?.understanding === 'absent' ? null! : registerStudent?.understanding;
        }

        updateRegisterStudent({
          id: registerStudent.registerStudentId,
          registerStudent: {
            ...(registerStudent.attendance
              ? {
                  hasAttended:
                    registerStudent.attendance === 'attended' ||
                    registerStudent.attendance === 'late' ||
                    registerStudent.attendance === 'leftEarly' ||
                    registerStudent.attendance === 'leftEarly&Late',
                  isLate: registerStudent.attendance === 'late' || registerStudent.attendance === 'leftEarly&Late',
                  hasLeftEarly:
                    registerStudent.attendance === 'leftEarly' || registerStudent.attendance === 'leftEarly&Late'
                }
              : { hasAttended: null, isLate: null, hasLeftEarly: null }),
            understanding: registerStudent.understanding || null,
            engagement: registerStudent.engagement || null,
            registerId: lessonData?.data.id
          }
        });
        break;
      case handleOnChangeTypes.UNDERSTANDING:
        registerStudent.understanding = value;
        updateRegisterStudent({
          id: registerStudent.registerStudentId,
          registerStudent: {
            understanding: registerStudent.understanding || null,
            registerId: lessonData?.data.id
          }
        });
        break;
      case handleOnChangeTypes.ENGAGEMENT:
        registerStudent.engagement = value;
        updateRegisterStudent({
          id: registerStudent.registerStudentId,
          registerStudent: {
            engagement: registerStudent.engagement || null,
            registerId: lessonData?.data.id
          }
        });

        break;
      case handleOnChangeTypes.FAVORITE:
        registerStudent.starStudent = value;
        updateRegisterStudent({
          id: registerStudent.registerStudentId,
          registerStudent: {
            starStudent: registerStudent.starStudent || null
          }
        });

        break;
      case handleOnChangeTypes.ISSUE:
        registerStudent.issueDescription = value.issueDescription;
        registerStudent.issueReasons = value.issueReasons;
        registerStudent.issueSendToParent = value.issueSendToParent;
        updateRegisterStudent({
          id: registerStudent.registerStudentId,
          registerStudent: {
            issueDescription: registerStudent.issueDescription,
            issue_reasons: registerStudent.issueReasons.map((reason: any) => reason.value),
            issueSendToParent: registerStudent.issueSendToParent,
            issueStatus: 'pending'
          }
        });
        break;
      case handleOnChangeTypes.SELECTED_TO_UPDATE:
        registerStudent.selected = value.selected;
        break;
      default:
        throw new Error("handle on change type doesn't exists ", type);
    }

    setValues({
      ...values
    });
  };

  const [sortedBy, setSortedBy] = useState<{ column: string; sort: string } | undefined>();

  const sortingBy = useCallback(
    (column: string) => {
      let newState: any = { ...sortedBy };
      if (column === newState?.column) {
        if (newState?.sort === 'asc') {
          newState.sort = 'desc';
        } else if (newState?.sort === 'desc') {
          newState.sort = 'asc';
        }
      } else {
        newState = {
          column,
          sort: 'asc'
        };
      }
      if (!values) return;
      const orderedRegister: RegisterMappedToView = {
        ...values
      };
      if (newState.column === 'student') {
        // sorting twice by first name then last name
        orderedRegister.registeredStudents = _.orderBy(
          _.orderBy(values?.registeredStudents, (e) => e.student.name, newState.sort),
          (e) => e.student.lastName,
          newState.sort
        );
      } else if (newState.column === 'attendance') {
        orderedRegister.registeredStudents = _.orderBy(
          values?.registeredStudents,
          (e) => {
            return e.attendance;
          },
          newState.sort
        );
      } else if (newState.column === 'engagement') {
        orderedRegister.registeredStudents = _.orderBy(
          values?.registeredStudents,
          (e) => {
            return e.engagement;
          },
          newState.sort
        );
      } else if (newState.column === 'understanding') {
        orderedRegister.registeredStudents = _.orderBy(
          values?.registeredStudents,
          (e) => {
            return e.understanding;
          },
          newState.sort
        );
      }
      setValues({
        ...values,
        ...orderedRegister
      });
      setSortedBy(newState);
    },
    [sortedBy, values, setValues]
  );

  const headers = useMemo(
    () => [
      {
        label: 'Student',
        value: 'student',
        className: 'col-3',
        style: {},
        showSortIcon: true,
        sort: () => (sortedBy?.column === 'student' ? sortedBy.sort : null),
        onClick: () => sortingBy('student')
      },
      {
        label: 'Attendance',
        value: 'attendance',
        className: 'col',
        style: {},
        showSortIcon: true,
        sort: () => (sortedBy?.column === 'attendance' ? sortedBy.sort : null),
        onClick: () => sortingBy('attendance')
      },
      {
        label: 'Engagement',
        value: 'engagement',
        className: 'col',
        style: {},
        showSortIcon: true,
        sort: () => (sortedBy?.column === 'engagement' ? sortedBy.sort : null),
        onClick: () => sortingBy('engagement')
      },
      {
        label: 'Understanding',
        value: 'understanding',
        className: 'col',
        style: {},
        showSortIcon: true,
        sort: () => (sortedBy?.column === 'understanding' ? sortedBy.sort : null),
        onClick: () => sortingBy('understanding')
      },
      {
        label: 'Actions',
        value: 'actions',
        className: 'col',
        style: {},
        showSortIcon: false,
        sort: () => (sortedBy?.column === 'actions' ? sortedBy.sort : null),
        onClick: () => sortingBy('actions')
      },
      {
        label: moment(lessonData?.data?.attributes?.classTime).startOf('day').isSame(moment().startOf('day'))
          ? 'Previous Homework'
          : 'Homework',
        value: 'homework',
        className: 'col-1',
        style: {},
        showSortIcon: false,
        sort: () => (sortedBy?.column === 'homework' ? sortedBy.sort : null),
        onClick: () => sortingBy('homework')
      }
    ],
    [sortedBy, values, setValues, sortingBy]
  );

  return (
    <LessonRegisterStyled>
      <div className="table-header d-flex flex-row justify-content-between gap-3" style={{ padding: '15px' }}>
        {headers.map((header) => (
          <div
            key={header.value}
            className={` a-s-t-th text-center d-flex flex-row align-items-center justify-content-center gap-2 ${header.className}`}
            style={header.style}
            onClick={header.onClick}
          >
            {header.label}{' '}
            {header.showSortIcon && (
              <>
                <div className="d-flex flex-column ">
                  <UpArrow
                    className={header.sort && (header.sort() as any) == 'asc' ? 'up-arrow active-arrow' : 'up-arrow'}
                  />
                  <DownArrow
                    className={
                      header.sort && (header.sort() as any) == 'desc' ? 'down-arrow active-arrow' : 'down-arrow'
                    }
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <div className="d-flex flex-column mt-3">
        {values?.registeredStudents?.map((registerStudent: any) => {
          return (
            <div
              key={registerStudent.id}
              className="table-row d-flex flex-row justify-content-between align-items-center gap-3"
            >
              <div className="d-flex flex-row gap-3 col-3">
                <Avatar
                  className="student-avatar mt-2"
                  name={registerStudent.avatarImageName}
                  width="48px"
                  height="48px"
                />
                <div>
                  <Paragraph className="student-name m-0 p-0" ellipsis={{ tooltip: true }} style={{ width: '140px' }}>
                    {_.capitalize(registerStudent.student.name)} {_.capitalize(registerStudent.student.lastName)}
                  </Paragraph>

                  <div className="student-id">{registerStudent.student.studentId}</div>
                </div>
                <div className="d-flex flex-row  gap-2 mt-2">
                  {registerStudent.freeTrial && (
                    <Tooltip title="Trial Student" style={{ width: '150px' }}>
                      <PaidIcon style={{ marginTop: '3px' }} />
                    </Tooltip>
                  )}
                  {registerStudent.student.isSen && (
                    <Tooltip title="Special Needs" style={{ width: '150px' }}>
                      <UnPaidIcon width={24.3} height={24.3} />
                    </Tooltip>
                  )}
                  {/* <IconComponents.StudentClassCancelIconComponent  style={{ marginTop: '3px' }}  svg={{width:18,height:18}} /> */}
                  {registerStudent?.student_class?.data?.attributes?.cancellationRequestDate && (
                    <Tooltip
                      style={{ width: '150px' }}
                      title={`${
                        moment(registerStudent?.student_class?.data?.attributes?.cancellationRequestDate).isAfter(
                          moment()
                        )
                          ? 'Cancelling on'
                          : 'Cancelled on'
                      } ${moment(registerStudent?.student_class?.data?.attributes?.cancellationRequestDate).format(
                        'DD MMM YYYY'
                      )}`}
                    >
                      <IconComponents.StudentClassCancelIconComponent
                        style={{ marginTop: '3px' }}
                        svg={{ width: 18, height: 18 }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
              <FormControl className="d-flex flex-row align-items-center justify-content-center col">
                <Select
                  containerclass="d-flex flex-row justify-content-center"
                  value={
                    registerStudent?.attendance
                      ? options.attendance.find(
                          (ele) => ele.value?.toString() == registerStudent.attendance?.toString()
                        )
                      : null
                  }
                  name="Select option"
                  dropdownStyle={{ color: 'green' }}
                  options={options.attendance}
                  disabled={disabled || lessonStatus == 'upcoming'}
                  onChange={(value: boolean) => {
                    handleOnChange(
                      handleOnChangeTypes.ATTENDANCE,
                      _.toString(registerStudent.id),
                      value ? value : null
                    );
                  }}
                />
              </FormControl>
              <FormControl className="col">
                <Select
                  containerclass="d-flex flex-row justify-content-center"
                  value={
                    registerStudent?.engagement
                      ? options.engagement.find(
                          (ele) => ele.value?.toString() == registerStudent.engagement?.toString()
                        )
                      : null
                  }
                  name="Select option"
                  disabled={disabled || !registerStudent.attendance || registerStudent.attendance == 'absent'}
                  options={options.engagement}
                  onChange={(value: string) => {
                    handleOnChange(
                      handleOnChangeTypes.ENGAGEMENT,
                      _.toString(registerStudent.id),
                      value ? value : null
                    );
                  }}
                />
              </FormControl>
              <FormControl className="d-flex flex-row  align-items-center justify-content-center col">
                <Select
                  containerclass="d-flex flex-row justify-content-center"
                  value={
                    registerStudent?.understanding
                      ? options.understanding.find(
                          (ele) => ele.value?.toString() == registerStudent.understanding?.toString()
                        )
                      : null
                  }
                  name="Select option"
                  disabled={disabled || !registerStudent.attendance || registerStudent.attendance == 'absent'}
                  options={options.understanding}
                  onChange={(value: string) => {
                    handleOnChange(
                      handleOnChangeTypes.UNDERSTANDING,
                      _.toString(registerStudent.id),
                      value ? value : null
                    );
                  }}
                />
              </FormControl>
              <div className="col d-flex flex-row justify-content-center align-items-center gap-3 col">
                <div
                  style={
                    registerStudent.attendance == 'absent' || !registerStudent.attendance
                      ? { cursor: 'not-allowed', opacity: '0.4' }
                      : {}
                  }
                >
                  {!registerStudent?.starStudent ? (
                    <StarIcon
                      onClick={() => {
                        if (disabled || !registerStudent.attendance || registerStudent.attendance == 'absent') return;
                        handleOnChange(handleOnChangeTypes.FAVORITE, _.toString(registerStudent.id), true);
                      }}
                    />
                  ) : (
                    <StartClickedIcon
                      onClick={() => {
                        if (disabled || !registerStudent.attendance || registerStudent.attendance == 'absent') return;
                        handleOnChange(handleOnChangeTypes.FAVORITE, _.toString(registerStudent.id), false);
                      }}
                    />
                  )}
                </div>
                <StudentTeacherNotes
                  disabled={disabled}
                  disableButton={registerStudent.attendance == 'absent' || !registerStudent.attendance}
                  cellData={{
                    rowIndex: 1,
                    columnId: 1,
                    issueDescription: registerStudent?.issueDescription!,
                    issueReasons: registerStudent?.issueReasons!,
                    issueSendToParent: registerStudent?.issueSendToParent!,
                    issueStatus: registerStudent?.issueStatus!
                  }}
                  onChange={(payload) => {
                    handleOnChange(handleOnChangeTypes.ISSUE, _.toString(registerStudent.id), {
                      issueDescription: payload.issueDescription,
                      issueReasons: payload.issueReasons,
                      issueSendToParent: payload.issueSendToParent
                    });
                  }}
                  student={{
                    id: registerStudent.student.id,
                    studentId: registerStudent.student.studentId,
                    userName: registerStudent.student.username,
                    fullName: `${registerStudent.student.name} ${registerStudent.student.lastName}`,
                    created_at: registerStudent.createdAt!,
                    avatarImageName: registerStudent.avatarImageName
                  }}
                />
                <NoteIcon
                  width="20px"
                  color="#B5B4BE"
                  onClick={() => {
                    setAddNoteOpen(true);
                    setStudentId(registerStudent.student.id);
                  }}
                />
              </div>
              <HomeworkMarkingBtnStyled
                className="d-flex flex-row align-items-center col-1 bg-transparent"
                buttonType="text"
                disabled={!registerStudent?.homeworkSubmitted}
                onClick={async () => {
                  if (
                    moment(lessonData?.data?.attributes?.classTime).startOf('day').isSame(moment().startOf('day')) &&
                    registerStudent?.previousRegisterStudent
                  ) {
                    setSelectedRegister(
                      registerStudent?.previousRegisterStudent?.attributes?.register?.data?.id?.toString()
                    );
                    setIsHomeworkDisplay(registerStudent?.previousRegisterStudent?.id?.toString()!);
                  } else if (registerStudent?.id) {
                    setSelectedRegister(lessonData?.data.id);
                    setIsHomeworkDisplay(registerStudent.id);
                  }
                }}
              >
                {registerStudent.homeworkStatus != 'marked' && registerStudent.homeworkStatus != 'marked_late' ? (
                  <StatusComponent
                    status={
                      moment(lessonData?.data?.attributes?.classTime).startOf('day').isSame(moment().startOf('day')) &&
                      registerStudent?.previousRegisterStudent
                        ? registerStudent?.previousRegisterStudent?.attributes?.homeworkStatus
                        : registerStudent.homeworkStatus
                    }
                  />
                ) : (
                  <HomeworkButton
                    status={registerStudent.homeworkStatus}
                    homeworkSubmitted={registerStudent.homeworkSubmitted}
                    homeworkMarksPercentage={registerStudent.homeworkMarksPercentage}
                    onClick={() => {}}
                  />
                )}
              </HomeworkMarkingBtnStyled>
            </div>
          );
        })}
      </div>
      {!!selectedRegister && !!isHomeworkDisplay ? (
        lessonData.data?.attributes?.homeworkType === HomeworkTypes.MATERIAL ? (
          <HomeworkV2
            classId={lessonData?.data?.attributes?.class?.data?.attributes?.classId}
            homeworkReference={lessonData?.data?.attributes?.lesson_material?.data?.attributes?.homeworkReference}
            registerClassDate={lessonData?.data?.attributes?.classDate}
            registerId={lessonData?.data?.id}
            registerStudentId={isHomeworkDisplay}
            studentId={
              values?.registeredStudents?.find((e: any) => e.id.toString() === isHomeworkDisplay?.toString())?.student
                .studentId
            }
            studentName={
              values?.registeredStudents?.find((e: any) => e.id.toString() === isHomeworkDisplay?.toString())?.student
                .name
            }
            studentLastName={
              values?.registeredStudents?.find((e: any) => e.id.toString() === isHomeworkDisplay?.toString())?.student
                .lastName
            }
            modalIsOpen={!!isHomeworkDisplay}
            onRequestClose={async () => {
              setIsHomeworkDisplay(null);
              setSelectedRegister(null);
              await refetch();
            }}
            type="homework"
          />
        ) : (
          <StudentHomework
            registerId={selectedRegister!}
            registerStudentId={isHomeworkDisplay!}
            modalIsOpen={!!isHomeworkDisplay}
            forceMarking={true}
            closeModal={async () => {
              setIsHomeworkDisplay(null);
              setSelectedRegister(null);
              await refetch();
            }}
          />
        )
      ) : (
        <></>
      )}
      <StudentRegisterNotes
        addNoteOpen={addNoteOpen}
        setAddNoteOpen={setAddNoteOpen}
        studentId={studentId}
        regId={lessonData?.data?.id.toString()}
        classId={lessonData?.data?.attributes?.class?.data?.id?.toString()}
      />
    </LessonRegisterStyled>
  );
}

export const getAttendanceValue = (registerStudent: any) => {
  const hasAttended = registerStudent?.hasAttended;
  const hasLeftEarly = registerStudent?.hasLeftEarly;
  const isLate = registerStudent?.isLate;
  if (hasAttended && !hasLeftEarly && !isLate) {
    return { label: 'Attended', value: 'attended' };
  } else if (hasAttended && hasLeftEarly && !isLate) {
    return { label: 'Left Early', value: 'leftEarly' };
  } else if (hasAttended && hasLeftEarly && isLate) {
    return { label: 'Left Early And Late', value: 'leftEarly&Late' };
  } else if (hasAttended && !hasLeftEarly && isLate) {
    return { label: 'Late', value: 'late' };
  } else if (hasAttended === false) {
    return { label: 'Absent', value: 'absent' };
  } else {
    return null;
  }
};

export const mapRegisterToViewProps = (register: ApiResponseData<Register>): RegisterMappedToView => ({
  teacherName:
    register?.attributes?.teacher?.data?.attributes?.name +
    ' ' +
    register?.attributes?.teacher?.data?.attributes?.lastName,
  attendanceMarkedTime: register?.attributes?.attendanceMarkedTime,
  classDateTime: utcToLocalDateTime(register?.attributes?.classDate, register?.attributes?.startTime).format(),
  attendanceMarkedBy: {
    id: register?.attributes?.attendance_marked_by?.data?.id!,
    name: register?.attributes?.attendance_marked_by?.data?.attributes?.name!
  },
  status: register?.attributes.status,
  attendanceMarked: register?.attributes?.attendanceMarked,
  registeredStudents:
    register?.attributes?.register_students?.data?.map(
      (registerStudent) =>
        ({
          ...registerStudent?.attributes,
          student_class: registerStudent.attributes?.student_class,
          updatedAt: registerStudent.attributes.updatedAt,
          id: _.toString(registerStudent.id),
          student: {
            id: _.toString(registerStudent.attributes?.student?.data?.id),
            lastName: registerStudent.attributes?.student?.data?.attributes.lastName!,
            username: registerStudent.attributes?.student?.data?.attributes.user?.data?.attributes?.username!,
            name: registerStudent.attributes?.student?.data?.attributes.name!,
            studentId: registerStudent.attributes?.student?.data?.attributes?.studentId!,
            isSen: registerStudent.attributes?.student?.data?.attributes?.isSen!,
            createdAt: registerStudent.attributes?.student?.data?.attributes?.createdAt!
          },
          avatarImageName: registerStudent.attributes?.student?.data?.attributes?.avatarImageName!,
          freeTrial: registerStudent.attributes?.freeTrial!,
          issueDescription: registerStudent.attributes?.issueDescription,
          issueStatus: registerStudent?.attributes?.issueStatus!,
          issueReasons: registerStudent.attributes?.issue_reasons?.data.map(
            (issueReason: { attributes: { name: any }; id: { toString: () => any } }) => ({
              label: issueReason.attributes.name,
              value: issueReason.id.toString()
            })
          ),
          attendance: registerStudent.attributes ? getAttendanceValue(registerStudent?.attributes)?.value : null,
          understanding: registerStudent.attributes?.understanding,
          engagement: registerStudent.attributes?.engagement,
          homeworkMarkingStatus: registerStudent.attributes?.homeworkMarkingStatus,
          starStudent: registerStudent.attributes?.starStudent,
          createdAt: registerStudent.attributes?.createdAt!,
          issueSendToParent: registerStudent.attributes?.issueSendToParent,
          lesson_material: register?.attributes?.lesson_material?.data
        }) as RegisterStudentMappedToView
    ) || []
});

export type RegisterMappedToView = {
  teacherName: string;
  attendanceMarked?: boolean;
  status: RegisterStatues;
  attendanceMarkedTime?: string;
  attendanceMarkedBy?: { id: EntityId; name: string };
  registeredStudents: RegisterStudentMappedToView[];
  classDateTime: string;
};

export type RegisterStudentMappedToView = {
  student_class: {
    data: ApiResponseData<StudentClass>;
  };
  selected?: boolean;
  updatedAt: string;
  id: string;
  student: {
    id: string;
    username: string;
    name: string;
    lastName: string;
    studentId: string;
    isSen: boolean;
    createdAt?: string;
  };
  avatarImageName: string;
  freeTrial: boolean;
  issueDescription: string;
  issueReasons: { label: string; value: string }[];
  attendance: string;
  understanding: string;
  engagement: string;
  homeworkMarkingStatus: string;
  starStudent: boolean;
  createdAt?: string;
  issueSendToParent?: boolean;
  issueStatus?: string;
  lesson_material?: ApiResponseData<LessonMaterial>;
};

export enum handleOnChangeTypes {
  ATTENDANCE,
  UNDERSTANDING,
  ENGAGEMENT,
  FAVORITE,
  ISSUE,
  SELECTED_TO_UPDATE
}

export type LessonRegisterProps = {};
