import { message } from 'antd';
import { FormControlError } from 'app/components/v1/Input';
import { registerStudentApi } from 'app/services/register-student';
import { registersApi, useGetLessonQuery } from 'app/services/registers';
import { HomeworkTypes, RegisterStatues, UpdateRegisterRequestBody, UserType } from 'app/services/types';
import { uploadApi } from 'app/services/upload';
import { MAIN_SELECTORS, RegisterStudent } from 'app/store';
import { localDateTimeToUtc, mapToFile, utcToLocalDateTime } from 'app/utils/utils';
import { deadline, lessonFiles, questions, solutions, totalMarks, yup } from 'app/utils/validations';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import LessonHeader from './components/LessonHeader';
import { mapRegisterToViewProps } from './register';
import { ResourcesFormValues } from './resources';

export const SubmittingStateContext = createContext<{
  values?: any;
  setValues?: any;
  errors?: FormControlError;
  setErrors?: any;
  lessonData?: any;
  isSubmittingLesson: boolean;
  disabled?: boolean;
  setDisabled?: any;
  enableHomeworkConfirmation?: any;
  onSubmit?: any;
  formik?: any;
  handleValueChange?: any;
  refetch?: any;
  homeworkTypes?: HomeworkType[];
  setIsSubmittingLesson: React.Dispatch<React.SetStateAction<boolean>>;
}>({ isSubmittingLesson: false, setIsSubmittingLesson: () => {} });

export interface ILessonProps {}
export type HomeworkType = {
  value: string;
  label: string;
};

export default function Lesson(props: ILessonProps) {
  const homeworkTypes: HomeworkType[] = [
    {
      value: HomeworkTypes.FILE,
      label: 'Upload homework'
    },
    {
      value: HomeworkTypes.MATERIAL,
      label: 'Premade Material'
    }
  ];
  const user = useSelector(MAIN_SELECTORS.user);
  const { lessonId } = useParams();
  const { data: lessonData, refetch } = useGetLessonQuery(lessonId!);
  const [isSubmittingLesson, setIsSubmittingLesson] = useState(false);
  const [errors, setErrors] = useState<any>(null!);
  const [disabled, setDisabled] = useState<any>(false);
  const [values, setValues] = useState<any>({
    hasHomework: true,
    lessonMaterial: null,
    selectedHomeworkTypeId: HomeworkTypes.MATERIAL
  });
  const [getRegisterStudents] = registerStudentApi.useLazyGetRegisterStudentsQuery();
  const [updateLesson] = registersApi.useUpdateLessonMutation();
  const onDataSaved = useCallback(() => {
    message.success('Homework is saved successfully');
  }, []);
  const onSaveDataFail = useCallback(() => {
    message.error('Something wrong');
  }, []);

  const [deleteFile] = uploadApi.useDeleteFileMutation();

  const solutionRequired = lessonData?.data.attributes.class.data.attributes.solutionRequired;

  const formik = useFormik<ResourcesFormValues>({
    initialValues: values,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: yup.object().shape({
      selectedHomeworkTypeId: yup.string().required(),
      lessonFiles: lessonFiles(),
      lessonMaterial: yup.array().when(['hasHomework', 'selectedHomeworkTypeId'], {
        is: (e: boolean, selectedHomeworkTypeId: string) => !!e && selectedHomeworkTypeId === HomeworkTypes.MATERIAL,
        then: () => yup.mixed().nullable().required(),
        otherwise: () => yup.mixed().nullable().optional()
      }),
      questions: yup.array().when(['hasHomework', 'selectedHomeworkTypeId'], {
        is: (e: boolean, selectedHomeworkTypeId: string) => !!e && selectedHomeworkTypeId === HomeworkTypes.FILE,
        then: () => questions(),
        otherwise: () => yup.array().label('questions').nullable().optional()
      }),
      solutions: solutionRequired
        ? yup.array().when(['hasHomework', 'selectedHomeworkTypeId'], {
            is: (e: boolean, selectedHomeworkTypeId: string) => !!e && selectedHomeworkTypeId === HomeworkTypes.FILE,
            then: () => solutions(),
            otherwise: () => yup.array().label('solutions').nullable().optional()
          })
        : yup.array().nullable().optional(),
      deadline: yup.string().when(['hasHomework'], {
        is: (e: boolean) => !!e,
        then: () => deadline(),
        otherwise: () => yup.string().label('deadline').nullable().optional()
      }),
      totalMarks: yup
        .number()
        .nullable()
        .when(['hasHomework', 'selectedHomeworkTypeId'], {
          is: (e: boolean, selectedHomeworkTypeId: string) => !!e && selectedHomeworkTypeId === HomeworkTypes.FILE,
          then: () => totalMarks(),
          otherwise: () => yup.number().label('total marks').nullable().optional()
        })
    }),
    onSubmit: async (values) => {
      await onSubmit(values);
    }
  });

  const enableHomeworkConfirmation =
    disabled ||
    (formik.values.hasHomework &&
      (!!formik.values.deadline || !!formik.values.solutions?.length || !!formik.values.questions?.length));

  const handleValueChange = useCallback(
    async (inputName: string, value: any) => {
      let newValues = {
        ...values
      } as any;
      switch (inputName) {
        case 'selectedHomeworkTypeId':
          if (!formik.values.deadline && value === HomeworkTypes.MATERIAL) {
            const deadline = utcToLocalDateTime(
              lessonData?.data?.attributes?.classDate!,
              lessonData?.data?.attributes?.startTime
            )
              .add(7, 'days')
              .toDate();

            await formik.setFieldValue('deadline', deadline);
            newValues = {
              ...newValues,
              deadline: deadline
            };
          }
          await formik.setFieldValue('selectedHomeworkTypeId', value);
          setValues({
            ...newValues,
            selectedHomeworkTypeId: value
          });
          // await onSubmit({
          //   ...formik.values,
          //   ...newValues,
          //   selectedHomeworkTypeId: value
          // });
          break;
        case 'lessonMaterial':
          await formik.setFieldValue('lessonMaterial', value);
          if (!formik.values.deadline) {
            const deadline = utcToLocalDateTime(
              lessonData?.data?.attributes?.classDate!,
              lessonData?.data?.attributes?.startTime
            )
              .add(7, 'days')
              .toDate();

            await formik.setFieldValue('deadline', deadline);
            newValues = {
              ...newValues,
              deadline: deadline
            };
          }
          setValues({
            ...newValues,
            lessonMaterial: value
          });
          await onSubmit({
            ...formik.values,
            ...newValues,
            lessonMaterial: value,
            hasHomework: value ? true : false,
            deadline: value ? formik.values.deadline : null
          });
          break;
        case 'questions':
          // eslint-disable-next-line no-case-declarations
          newValues = {
            ...values,
            questions: _.cloneDeep(value.files)
          };
          if (value.files.length) {
            if (!formik.values.deadline) {
              const deadline = utcToLocalDateTime(
                lessonData?.data?.attributes?.classDate!,
                lessonData?.data?.attributes?.startTime
              )
                .add(7, 'days')
                .toDate();

              await formik.setFieldValue('deadline', deadline);
              newValues = {
                ...newValues,
                deadline: deadline
              };
            }
            if (!formik.values.totalMarks) {
              await formik.setFieldValue('totalMarks', 100);
              newValues = {
                ...newValues,
                totalMarks: 100
              };
            }
          } else {
            newValues.deadline = null;
            newValues.totalMarks = null;
            newValues.hasHomework = false;
            await formik.setFieldValue('deadline', null);
            await formik.setFieldValue('totalMarks', null);
            await formik.setFieldValue('hasHomework', false);
          }

          await formik.setFieldValue('questions', _.cloneDeep(value.files));
          setValues({
            ...newValues
          });
          await onSubmit({
            ...formik.values,
            ...newValues,
            questions: _.cloneDeep(value.files)
          });
          break;
        case 'solutions':
          await formik.setFieldValue('solutions', _.cloneDeep(value.files));
          setValues({
            ...values,
            solutions: _.cloneDeep(value.files)
          });
          await onSubmit({
            ...values,
            ...formik.values,
            solutions: _.cloneDeep(value.files)
          });
          break;
        case 'lessonFiles':
          await formik.setFieldValue('lessonFiles', _.cloneDeep(value.files));
          setValues({
            ...values,
            lessonFiles: _.cloneDeep(value.files)
          });
          await onSubmit({
            ...values,
            ...formik.values,
            lessonFiles: _.cloneDeep(value.files)
          });

          break;
        case 'totalMarks':
          formik.setFieldValue('totalMarks', _.toNumber(value));
          setValues({
            ...values,
            totalMarks: _.toNumber(value)
          });
          await onSubmit({
            ...values,
            ...formik.values,
            totalMarks: _.toNumber(value)
          });
          break;
        case 'hasHomework':
          if (!formik.values.hasHomework || !enableHomeworkConfirmation) {
            formik.setFieldValue('hasHomework', value.target.checked);
            setValues({
              ...values,
              hasHomework: value.target.checked
            });
          }

          break;
        case 'deadline':
          await formik.setFieldValue(
            'deadline',
            value?.apiDate?.at(0) ? moment(value?.apiDate?.at(0)).toDate() : undefined!
          );
          setValues({
            ...values,
            deadline: value?.apiDate?.at(0) ? moment(value?.apiDate?.at(0)).toDate() : undefined!
          });
          await onSubmit({
            ...values,
            ...formik.values,
            deadline: value?.apiDate?.at(0) ? moment(value?.apiDate?.at(0)).toDate() : undefined!
          });
          break;
      }
      setTimeout(async () => {
        errors && delete errors['questions'];
        errors && delete errors['solutions'];
        errors && delete errors['lessonFiles'];
        errors && delete errors['totalMarks'];
        errors && delete errors['hasHomework'];
        errors && delete errors['deadline'];
        if (inputName === 'hasHomework' && value.target.checked) {
          setErrors({
            ...errors,
            questions: true,
            solutions: true,
            totalMarks: true,
            deadline: true
          });
        } else {
          setErrors({
            ...errors,
            ...(await formik.validateForm())
          });
        }
      }, 10);
    },
    [lessonData, formik, formik.values.hasHomework, setValues, values, enableHomeworkConfirmation, errors]
  );

  useEffect(() => {
    async function loadAll() {
      if (!lessonData) return;
      setDisabled(
        ([RegisterStatues.COMPLETED].includes(lessonData?.data.attributes.status) &&
          user.userType != UserType.Admin.toLowerCase()) ||
          (user.userType != UserType.Admin.toLowerCase() && lessonData?.data.attributes.attendanceMarked)
      );

      const _values = {
        ...values,
        lessonMaterial: lessonData?.data?.attributes?.lesson_material?.data,
        selectedHomeworkTypeId: lessonData?.data?.attributes?.homeworkType ?? HomeworkTypes.MATERIAL,
        deadline: lessonData?.data.attributes.homeworkDeadline
          ? utcToLocalDateTime(lessonData?.data.attributes.homeworkDeadline!).format()
          : null,
        totalMarks: lessonData?.data.attributes.homeworkMarks,
        hasHomework: true,
        lessonFiles: lessonData?.data.attributes.lessonFiles?.data?.map((file: any) => mapToFile(file)),
        solutions: lessonData?.data.attributes.homeworkSolutions?.data?.map((file: any) => mapToFile(file)),
        questions: lessonData?.data.attributes.homeworkFiles?.data?.map((file: any) => mapToFile(file))
      };

      const mappedRegister = mapRegisterToViewProps(lessonData?.data);

      const registeredStudents = [];

      for (const registerStudent of mappedRegister.registeredStudents) {
        const previousRegisters = await getRegisterStudents({
          requestQuery: qs.stringify({
            filters: {
              register: {
                class: lessonData?.data?.attributes?.class?.data?.id,
                classTime: {
                  $lt: lessonData?.data?.attributes?.classTime
                }
              },
              student: registerStudent.student.id
            },
            populate: ['register'],
            pagination: {
              limit: 2
            },
            sort: ['id:desc']
          })
        }).unwrap();

        const loadedRegisterStudent = await getRegisterStudents({
          requestQuery: qs.stringify({
            filters: {
              id: registerStudent?.id
            },
            populate: ['register'],
            pagination: {
              limit: 1
            }
          })
        }).unwrap();

        registeredStudents.push({
          ...registerStudent,
          ...loadedRegisterStudent?.data?.at(0)?.attributes,
          previousRegisterStudent: previousRegisters?.data?.at(0),
          lastUpdate: moment(registerStudent.updatedAt).toString(),
          registerStudentId: _.toString(registerStudent.id),
          studentId: _.toString(registerStudent.student.id),
          attendance: registerStudent.attendance,
          engagement: registerStudent.engagement,
          starStudent: registerStudent.starStudent,
          understanding: registerStudent.understanding,
          issueDescription: registerStudent.issueDescription,
          issueReasons: registerStudent.issueReasons,
          issueSendToParent: registerStudent.issueSendToParent,
          issueStatus: registerStudent.issueStatus
        });
      }

      mappedRegister.registeredStudents = registeredStudents as any;

      setValues({
        ..._values,
        ...mappedRegister
      });
      formik.setValues({
        ..._values,
        ...mappedRegister
      });
    }
    loadAll();
  }, [lessonData?.data]);

  useEffect(() => {
    formik.validateForm().then((e) => {
      if (
        !values?.registeredStudents?.length ||
        !values?.registeredStudents?.every((e: RegisterStudent) => e.attendance && e.engagement && e.understanding)
      ) {
        setErrors({
          ...e,
          registerStudents: true
        });
      } else {
        setErrors({
          ...e
        });
      }
    });
  }, [values]);

  useEffect(() => {
    refetch();
  }, [isSubmittingLesson]);

  async function onSubmit(currentValues: any) {
    try {
      const newValues: ResourcesFormValues = { ...currentValues };

      const requestBody: UpdateRegisterRequestBody = {};
      requestBody.homeworkType = newValues.selectedHomeworkTypeId;
      requestBody.hasHomework = newValues.hasHomework;
      requestBody.lesson_material = Number(newValues?.lessonMaterial?.id);

      if (newValues?.questions?.length) {
        const oldValuesIds = formik?.values?.questions?.map((value) => value.id!);

        if (oldValuesIds && oldValuesIds?.length > 0) {
          requestBody.homeworkFiles = oldValuesIds.concat(newValues.questions.map((file) => file.id!));
        } else {
          requestBody.homeworkFiles = newValues.questions.map((file) => file.id!);
        }
      } else if (newValues?.questions === null) {
        requestBody.homeworkFiles = null!;
      }
      if (newValues?.solutions?.length) {
        const oldValuesIds = formik?.values?.solutions?.map((value) => value.id!);

        if (oldValuesIds && oldValuesIds?.length > 0) {
          requestBody.homeworkSolutions = oldValuesIds.concat(newValues.solutions.map((file) => file.id!));
        } else {
          requestBody.homeworkSolutions = newValues.solutions.map((file) => file.id!);
        }
      } else if (newValues?.solutions === null) {
        requestBody.homeworkSolutions = null!;
      }

      requestBody.homeworkDeadline = newValues.deadline
        ? localDateTimeToUtc(newValues.deadline.toString()).toISOString()
        : null!;
      requestBody.homeworkMarks = newValues.totalMarks ? newValues.totalMarks?.toString()! : null!;
      if (newValues?.lessonFiles?.length) {
        const oldValuesIds = formik?.values?.lessonFiles?.map((value) => value.id!);

        if (oldValuesIds && oldValuesIds?.length > 0) {
          requestBody.lessonFiles = oldValuesIds.concat(newValues.lessonFiles.map((file) => file.id!));
        } else {
          requestBody.lessonFiles = newValues.lessonFiles.map((file) => file.id!);
        }
      } else if (newValues?.lessonFiles === null) {
        requestBody.lessonFiles = null!;
      }

      await updateLesson({
        registerId: lessonId!,
        register: requestBody as any
      });

      onDataSaved();
      await formik.validateForm();
    } catch (e) {
      onSaveDataFail();
    }
  }

  return (
    <div className="p-0 bg-light border-end px-5">
      <SubmittingStateContext.Provider
        value={{
          disabled,
          setDisabled,
          isSubmittingLesson,
          setIsSubmittingLesson,
          errors,
          setErrors,
          lessonData,
          values,
          setValues,
          formik,
          handleValueChange,
          enableHomeworkConfirmation,
          onSubmit,
          refetch,
          homeworkTypes
        }}
      >
        <LessonHeader
          errors={errors}
          setErrors={setErrors}
          userType={user.userType}
          isSubmittingLesson={isSubmittingLesson}
          setIsSubmittingLesson={setIsSubmittingLesson}
        />
        <div className="mt-0 px-2 px-sm-4 col-12 bg-light mb-8">
          <Outlet />
        </div>
      </SubmittingStateContext.Provider>
    </div>
  );
}
